.content-benefits {
    display      : flex;
    align-items  : center;
    width        : 100vw;
    height       : 590px;
    position     : relative;
    left         : -28px;
    padding-left : 2rem;
    padding-right: 2rem;
    margin-top: -5rem;
    margin-bottom: -7rem;
}

.content-benefits .swiper-slide {
    display: flex;
    width: auto !important;
    height: auto !important;
}

.content-benefits .swiper-wrapper {
    height: auto;
}

@media screen and (max-width: 600px) {
    .content-benefits {
        position: relative;
        height: 616px;
    }
}