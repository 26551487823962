.button-order  {
    display: block;
    width: 100%;
    height: 46px;
    margin: 0 auto;
    text-align: center;
    font-weight: 800;
    font-size: 1.375rem;
    line-height: 1.878125rem;
    padding: 8px 20px 8px 20px;
    background-color: #4433E0;
    color: #F9F9F9;
    border-radius: 15px;
    text-decoration: none;
    transform: scale(1);
    transition: transform .4s cubic-bezier(0,.8,.27,1.73);
  }

  .button-order:hover {
    transform: scale(1.05);
  }

  .button-order:active {
    transform: scale(1);
  }