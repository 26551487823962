.evidence-carousel {
    padding-right: 88%;
    width        : 100%;
    overflow     : initial;
}


.evidence-carousel .swiper-wrapper {
    width: 280%;
}

.evidence-carousel .swiper-slide {
    width: max-content !important;
}

@media screen and (max-width: 600px) {
    .evidence-carousel {
        width: 216%;
    }
}