.does-it-work-carousel {
    width: 100%;
    overflow: initial;
}

.does-it-work-carousel .swiper-slide {
    width: max-content !important;
}

@media screen and (max-width: 600px) {
    .does-it-work-carousel {
        width        : 100%;
        padding-right: 8.5rem;
    }
}