.content-service {
    display: flex;
    align-items: center;
    width: 100vw;
    height: 590px;
    position: relative;
    top: -21%;
    left: -28px;
    padding-left: 2rem;
    padding-right: 2rem;
}

.content-service .swiper-slide {
    display: flex;
    width: auto !important;
    height: auto !important;
}

.content-service .swiper-wrapper {
    height: auto;
}

@media screen and (max-width: 600px) {
    .content-service {
        height: '558px';
        top: -18%;
    }
}