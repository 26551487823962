 .tracking-scrollBar::-webkit-scrollbar {
     width: 11px;
 }
 

 .tracking-scrollBar::-webkit-scrollbar-thumb {
     background-color: #776cb6;
     border-radius: 3px;
 }

 .round, .round button, .round button div {
   width: 50px!important;
   height: 50px!important;
   border-radius: 50%!important;
 }

 .tracking-email-link{
    opacity: .7;
    color: #fcfcff;
    background-image: url(https://uploads-ssl.webflow.com/63dca0a1242fd10a4612d6cc/640f42a323d1bb993e9613c9_mail.svg);
    background-position: 0%;
    background-repeat: no-repeat;
    background-size: auto;
    margin-bottom: 5px;
    padding-left: 44px;
    font-family: Mulish, sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 178%;
    text-decoration: none;
    transition: opacity .2s;
    margin-bottom: 20px;
 }

 .tracking-email-link:hover{
    opacity: .95;
 }

 .tracking-footer-links{
    color: #bcb4f3;
    text-decoration: underline;
    margin-bottom: 16px;
    font-family: Mulish, sans-serif;
    line-height: 150%;
    transition: color .2s;
    display: block;
 }

 .tracking-link-2{
    color: #bcb4f3;
 }

 .tracking-social-links{
    width: 40px;
    height: 40px;
    max-width: 100%;
    background-color: #151229;
    border-radius: 100px;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    transition: all .2s;
    display: flex;
 }

 .tracking-social-links:hover{
    filter: invert();
    transform: translate(0, -5px);
 }