.activePoint {
    background-color: #4433E0 !important;
    width           : 25.5px !important;
    border-radius   : 30px !important;
}

.activePoint,
.notActive {
    transition: all .5s ease;
}

.notActive {
    background-color: transparent;
    border          : 2px solid #4433E0;
}

.content-header__title,
.content-header__title > div > span {
    display           : -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.content-header__title > div > span {
    display           : -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

