.button-next {
    width: 311px;
    position: absolute;
    bottom: 0;
    margin: 2rem;
    margin-top: 0;
}

.button-next-desktop {
    width: 90%;
    margin: 2rem;
}