*  {
    margin    : 0;
    padding   : 0;
    box-sizing: border-box;
}

html,
body {
    height  : 100%;
    position: relative;
}

#root {
    height: auto;
}

body {
    background-color: #f9f9f9 !important;
}
