.aciveImageSaveTime {
    background-position: center center;
    background-size: cover; 
}

div.provider-carousel {
    padding-right: 16rem ;
}

.provider-carousel .swiper-wrapper .swiper-slide {
    width: max-content !important;
}